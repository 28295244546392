<template>
  <div class="bg-white overflow-auto rounded-lg p-4 mt-4">
    <b-tabs pills content-class="mt-3" align="left">
      <b-tab exact-active-class="active" title="Realtor Stage">
        <realtor-stage />
      </b-tab>
      <b-tab exact-active-class="active" title="Plot Sizes">
        <div class="d-flex justify-content-between align-items-center mb-4">
          <p class="text-primary mb-0">1/5 pages</p>

          <rm-base-button
            class="ml-2"
            text="New Plot Size"
            bgColor="tertiary--light"
            textColor="tertiary"
            ><rm-base-icon class="mr-2" name="plus-icon"
          /></rm-base-button>
        </div>
        <plot-size />
        <!-- <Table :table-data="inActiveClients" /> -->
      </b-tab>
      <b-tab exact-active-class="active" title="Payment Plans">
        <!-- <Table :table-data="allClients" /> -->
        <div class="d-flex justify-content-between align-items-center mb-4">
          <p class="text-primary mb-0">1/5 pages</p>

          <rm-base-button
            class="ml-2"
            text="New Plot Size"
            bgColor="tertiary--light"
            textColor="tertiary"
            ><rm-base-icon class="mr-2" name="plus-icon"
          /></rm-base-button>
        </div>
        <plot-size />
      </b-tab>
      <b-tab exact-active-class="active" title="Office Branch">
        <!-- <Table :table-data="allClients" /> -->
        <office-branch />
      </b-tab>

      <b-tab exact-active-class="active" title="System Settings">
        <!-- <Table :table-data="allClients" /> -->
        <div class="d-flex justify-content-between align-items-center mb-4">
          <p class="text-primary mb-0">1/5 pages</p>

          <rm-base-button
            text="New Role"
            bgColor="tertiary--light"
            textColor="tertiary"
            ><rm-base-icon class="mr-2" name="plus-icon"
          /></rm-base-button>
        </div>
        <change-request />
      </b-tab>

      <b-tab exact-active-class="active" title="Change Request Types">
        <!-- <Table :table-data="allClients" /> -->
        <div class="d-flex justify-content-between align-items-center mb-4">
          <p class="text-primary mb-0">1/5 pages</p>

          <rm-base-button
            text="New Role"
            bgColor="tertiary--light"
            textColor="tertiary"
            ><rm-base-icon class="mr-2" name="plus-icon"
          /></rm-base-button>
        </div>
        <change-request />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import PlotSize from "./components/PlotSize";
import OfficeBranch from "./components/OfficeBranch";
import RealtorStage from "./components/RealtorStage";
import ChangeRequest from "./components/ChangeRequest";
export default {
  components: {
    PlotSize,
    OfficeBranch,
    RealtorStage,
    ChangeRequest,
  },
};
</script>

<style lang="scss">
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  opacity: 1;
  font-size: 12px !important;
  color: var(--primary) !important;
  background-color: #fff;
  border-bottom: 2px solid var(--primary);
  border-radius: 0 !important;
}

.nav-pills .nav-link {
  font-weight: 900;
  color: var(--dark) !important;
  font-size: 12px !important;
}
</style>
