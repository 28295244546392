<template>
  <div class="bg-white">
    <b-table
      ref="clientsTable"
      thead-class="bg-gray-400 text-uppercase text-sm-2"
      tbody-class="text-sm"
      :selectable="true"
      hover
      striped
      :items="items"
      :fields="fields"
      @row-selected="onRowSelected"
    >
      <template v-slot:cell(check)="{ rowSelected, index }">
        <div class="d-flex justify-content-center align-items-center">
          <input
            type="checkbox"
            :checked="rowSelected"
            @click="handleRowSelect(rowSelected, index)"
          />
        </div>
      </template>
      <template #head(check)="">
        <input
          type="checkbox"
          :checked="selectAll && items.length == selected.length"
          @click="selectAll = !selectAll"
        />
      </template>

      <template #cell(commission)="data">
        <div class="d-flex">
          <div
            class="mr-3"
            v-for="(item, index) in data.item.commission"
            :key="index"
          >
            <div class="t-cards text-center rounded">
              {{ item.percentage + "%" }} <br />
              <small
                v-if="
                  item.label.toLowerCase() == 'biz-rep' ||
                  item.label.toLowerCase() == 'partner'
                "
                class="bg-primary--light text-primary d-inline-block px-1 rounded-lg"
              >
                {{ item.label }}
              </small>
              <small
                v-else-if="item.label.toLowerCase() == 'referral'"
                class="bg-tertiary--light text-tertiary d-inline-block px-1 rounded-lg"
              >
                {{ item.label }}
              </small>
              <small
                v-else
                class="bg-gray-100 text-dark d-inline-block px-1 rounded-lg"
              >
                {{ item.label }}
              </small>
            </div>
          </div>
        </div>
      </template>

      <!-- Action column -->
      <template #cell(action)>
        <div class="d-flex">
          <rm-base-button
            text="Edit"
            bgColor="primary--light"
            textColor="primary"
            ><rm-base-icon class="mr-2" name="edit-icon"
          /></rm-base-button>
          <rm-base-button
            class="ml-2"
            text="Delete"
            bgColor="danger--light"
            textColor="danger"
            ><rm-base-icon class="mr-2" name="cancel-icon"
          /></rm-base-button>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectAll: false,
      filterOptions: ["Select Branch", "Select Commissions"],
      selected: [],
      // Note `isActive` is left out and will not appear in the rendered table
      fields: [
        {
          key: "check",
          // sortable: true,
          label: "",
        },
        {
          key: "branch",
          // sortable: true,
          label: "Branch",
        },
        {
          key: "code",
          sortable: false,
          label: "Code",
        },
        {
          key: "address",
          sortable: false,
          label: "Address",
        },
        {
          key: "state",
          sortable: false,
          label: "State",
        },
        {
          key: "date_created",
          sortable: false,
          label: "Date Created",
        },
        {
          key: "action",
          sortable: false,
          label: "Action",
        },
      ],
      items: [
        {
          // isActive: true,
          branch: "Head Office",
          code: "HOF",
          address: "RealMax Place, Adetunji close, Lekki, Lagos",
          state: "Lagos",
          date_created: "06/03/2020",
          action: "",
        },
        {
          // isActive: true,
          branch: "Head Office",
          code: "HOF",
          address: "RealMax Place, Adetunji close, Lekki, Lagos",
          state: "Lagos",
          date_created: "06/03/2020",
          action: "",
        },
        {
          // isActive: true,
          branch: "Head Office",
          code: "HOF",
          address: "RealMax Place, Adetunji close, Lekki, Lagos",
          state: "Lagos",
          date_created: "06/03/2020",
          action: "",
        },
        {
          // isActive: true,
          branch: "Head Office",
          code: "HOF",
          address: "RealMax Place, Adetunji close, Lekki, Lagos",
          state: "Lagos",
          date_created: "06/03/2020",
          action: "",
        },
      ],
    };
  },
  methods: {
    onRowSelected(items) {
      this.selected = items;
    },
    selectAllRows() {
      this.$refs.clientsTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.clientsTable.clearSelected();
    },
    selectRow(index) {
      this.$refs.clientsTable.selectRow(index);
    },
    unSelectRow(index) {
      this.$refs.clientsTable.unselectRow(index);
    },
    handleRowSelect(isRowSelected, index) {
      if (!isRowSelected) {
        this.selectRow(index);
      } else {
        this.unSelectRow(index);
      }
    },
  },
  watch: {
    selectAll(v) {
      if (!v) {
        this.clearSelected();
      } else {
        this.selectAllRows();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.bordered {
  border-left: 1px solid var(--primary);
}

.t-cards {
  background: #fff;
  padding: 7px;
  box-shadow: -1px 1px 4px rgb(230, 230, 230);
}
</style>
